import { Link, withPrefix } from 'gatsby';
import React from 'react';

import styles from './Header.module.scss';

interface Props {
  siteTitle: string;
}

class Header extends React.PureComponent<Props> {
  public render() {
    return (
      <div className={styles.header}>
        <header className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <div>
                <h1>
                  <Link to="/">
                    <img
                      className={styles.header__logo}
                      src={withPrefix('qrpid-logo.svg')}
                      alt={this.props.siteTitle}
                    />
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
