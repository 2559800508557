import React, { ReactElement } from 'react';

import styles from './Block.module.scss';

interface IProps {
  children: ReactElement | ReactElement[];
  className?: string;
}

const Block: React.FC<IProps> = ({ children, className = '' }) => {
  return <section className={`${styles.container} ${className}`}>{children}</section>;
};

export default Block;
