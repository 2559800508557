import React, { ReactElement, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Spring } from 'react-spring/renderprops.cjs';

interface IProps {
  children: ReactElement | ReactElement[];
  className?: string;
}

const NarrowBlock: React.FC<IProps> = ({ children, className = '' }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <div className={`grid-container ${className}`}>
      <VisibilitySensor
        active={!isVisible}
        onChange={handleVisibilityChange}
        partialVisibility
        offset={{ bottom: 50 }}
      >
        {({ isVisible }) => (
          <div className="grid-x grid-margin-x">
            <div className="cell medium-12 large-offset-2 large-8">
              <Spring
                delay={300}
                to={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? 'translateY(0)' : 'translateY(25px)',
                }}
              >
                {(props) => <div style={{ ...props }}>{children}</div>}
              </Spring>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
};

export default NarrowBlock;
