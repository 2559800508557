import React, { ReactElement } from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';

import Header from '../Header/Header';

import styles from './Layout.module.scss';

interface IProps {
  children: ReactElement | ReactElement[];
}

const Layout: React.FC<IProps> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className={styles.layout}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          <footer>
            <div className="grid-container">
              <div className="grid-x grid-margin-x">
                <div className="cell small-12">
                  <span>
                    <strong>
                      © {new Date().getFullYear()} {` `}
                      <a href="https://www.egonoid.com" target="_blank" rel="noopener noreferrer">
                        egonoid
                      </a>
                    </strong>
                    , all rights reserved.
                    {` `}
                    {/* <Link to="/contact/">Contact</Link>
                  {` | `} */}
                    <Link to="/imprint/">Imprint</Link>
                    {` | `}
                    <Link to="/privacy/">Data Privacy</Link>
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )}
  />
);

export default Layout;
