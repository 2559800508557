import React, { ReactElement } from 'react';

import styles from './Hero.module.scss';

interface IProps {
  children: ReactElement | ReactElement[];
  video?: ReactElement;
  className?: string;
}

const Hero: React.FC<IProps> = ({ children, video, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6 large-6">{children}</div>
          <div className="cell small-12 medium-6 large-6">
            <div className="hide-for-small-only">{video}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
