import React, { ReactElement, useState } from 'react';
import StickyBox from 'react-sticky-box';
import VisibilitySensor from 'react-visibility-sensor';
import { Spring } from 'react-spring/renderprops.cjs';

import styles from './ImageBlock.module.scss';

enum ImageAlignment {
  LEFT,
  RIGHT,
}

interface IProps {
  src: string;
  children: ReactElement | ReactElement[];
  alignment?: ImageAlignment;
  alt?: string;
  className?: string;
}

const ImageBlock: React.FC<IProps> = ({
  src,
  children,
  alignment = ImageAlignment.LEFT,
  alt = '',
  className = '',
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <div className={`${styles.container} grid-container ${className}`}>
      <VisibilitySensor
        active={!isVisible}
        onChange={handleVisibilityChange}
        partialVisibility
        offset={{ bottom: 50 }}
      >
        {({ isVisible }) => (
          <div className="grid-x grid-margin-x">
            {alignment === ImageAlignment.LEFT && (
              <div className="cell small-12 medium-6 small-order-1 medium-order-1">
                <Spring
                  delay={300}
                  to={{
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {(props) => <img src={src} alt={alt} style={{ ...props }} />}
                </Spring>
              </div>
            )}

            <div className="cell small-12 medium-6 small-order-2 medium-order-2">
              <Spring
                delay={500}
                to={{
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {(props) => (
                  <StickyBox offsetTop={80} offsetBottom={80}>
                    <div className={styles.content} style={{ ...props }}>
                      {children}
                    </div>
                  </StickyBox>
                )}
              </Spring>
            </div>

            {alignment === ImageAlignment.RIGHT && (
              <div className="cell small-12 medium-6 small-order-1 medium-order-2">
                <Spring
                  delay={300}
                  to={{
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {(props) => <img src={src} alt={alt} style={{ ...props }} />}
                </Spring>
              </div>
            )}
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
};

export default ImageBlock;
export { ImageAlignment };
